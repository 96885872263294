@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 14px;

  --color-primary-100: #bebed3;
  --color-primary-200: #a6a6c2;
  --color-primary-300: #8d8db1;
  --color-primary-400: #7575a1;
  --color-primary-500: #60608d;
  --color-primary-600: #505075;
  --color-primary-700: #3f3f5d;
  --color-primary-800: #2f2f44;
  --color-primary-900: #1e1e2c;
  --color-secondary: #676772;
  --color-secondary-hover: #898994;
  --color-accent: #0277fa;
  --color-accent-glow: #0277fa4d;
  --color-accent-hover: #2389ff;
  --color-accent-disabled: #1e4674;
}

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center
           h-14 w-14 my-[1.125rem] mx-auto
           text-secondary hover:text-secondary-hover
           transition-all duration-300;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-[0.75rem] min-w-max left-16
           rounded-md shadow-md
           bg-primary-800 text-white
           text-xs font-bold
           transition-all duration-100 scale-0 origin-left;
  }
}

@layer utilities {
  body {
    @apply bg-primary-900
           scrollbar-thin scrollbar-thumb-gray-400 hover:scrollbar-thumb-gray-500 scrollbar-thumb-rounded-md;
  }

  h1 {
    @apply text-white
           text-3xl;
  }

  p {
    @apply text-white;
  }

  #root {
    @apply grid grid-cols-1;
  }

  #nprogress {
    @apply relative;
  }
}

#nprogress .bar {
  background: var(--color-accent-hover) !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--color-accent-hover),
    0 0 5px var(--color-accent-hover) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--color-accent-hover) !important;
  border-left-color: var(--color-accent-hover) !important;
}
